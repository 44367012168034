import * as React from 'react';
import '../styles/layout.css';
import styled from 'styled-components';
import {
    AppBar,
    AppBarProps,
    Box,
    Card,
    CardContent,
    Divider,
    Drawer,
    IconButton,
    Toolbar,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import {Location, LocationContext} from '@reach/router';
import {styled as muiStyled} from '@mui/material/styles';
import {AuthToken} from '../auth-token';
import {SearchInput} from './search-icon';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {IGraphQLMenuEntry} from '../../graphql.types';
import {mapStateToProps} from '../state/store';
import {connect} from 'react-redux';
import {updateMenuItems} from '../state/menu-items.slice';

interface ILayoutProps {
    children: any;
    menuItems: Array<IGraphQLMenuEntry>;
}

interface ILayoutState {
    isMenuOpened: boolean;
    isMobileMenuOpened: boolean;
    children: any;
}

const drawerWidth: number = 240;
class Layout extends React.Component<ILayoutProps, ILayoutState> {
    constructor(props: ILayoutProps) {
        super(props);

        this.state = {
            isMenuOpened: true,
            isMobileMenuOpened: false,
            children: props.children,
        };
    }

    public render(): React.ReactNode {
        const drawer: React.ReactNode = this.getDrawerContent();
        const toolbar: React.ReactNode = this.getToolbarContent();

        return isLoginPage() ? (<div></div>) : (
            <Box sx={{display: 'flex'}}>
                <GlobalAppBar
                    position="fixed"
                    elevation={2}
                    open={this.state.isMenuOpened}
                    className="no-print"
                >
                    {toolbar}
                </GlobalAppBar>
                <Box
                    component="nav"
                    className="no-print"
                >
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <NavigationDrawer
                        variant="temporary"
                        open={this.state.isMobileMenuOpened}
                        onClose={this.handleMobileDrawerToggle.bind(this)}
                        sx={{
                            'display': {xs: 'block', sm: 'none'},
                            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                        }}
                    >
                        {drawer}
                    </NavigationDrawer>
                    <NavigationDrawer
                        variant="persistent"
                        sx={{
                            'display': {xs: 'none', sm: 'block'},
                            '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                        }}
                        open={this.state.isMenuOpened}
                    >
                        {drawer}
                    </NavigationDrawer>
                </Box>
                <ContentWrapper
                    open={this.state.isMenuOpened}
                    component="main"
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <PageContent elevation={2} className="page-content">
                        <CardContent sx={{
                            padding: 0,
                        }}>
                            {this.state.children}
                        </CardContent>
                    </PageContent>
                </ContentWrapper>
            </Box>
        );
    }

    private getToolbarContent(): React.ReactNode {
        return (
            <header>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle.bind(this)}
                        sx={{color: '#757575', mr: 2, display: {xs: 'none', sm: 'block'}}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={this.handleMobileDrawerToggle.bind(this)}
                        sx={{color: '#757575', mr: 2, display: {xs: 'block', sm: 'none'}}}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            fontWeight: 400,
                        }}
                    >
                        Active Agent Handbuch
                    </Typography>
                    <SearchInput/>
                    <IconButton
                        color="inherit"
                        sx={{color: '#757575', mr: this.isDForceBuild() ? 0 : 2}}
                        aria-label="glossary"
                        edge="start"
                        onClick={this.openGlossary.bind(this)}
                    >
                        <MenuBookIcon />
                    </IconButton>
                    {!this.isDForceBuild() && (
                        <IconButton
                            color="inherit"
                            aria-label="logout"
                            edge="start"
                            onClick={this.logout.bind(this)}
                        >
                            <LogoutIcon />
                        </IconButton>
                    )}
                </Toolbar>
                <section
                    style={{
                        height: '48px',
                        backgroundColor: '#39917b',
                    }}
                ></section>
            </header>
        );
    }

    private isDForceBuild(): boolean {
        return process.env.GATSBY_IS_DFORCE === 'true';
    }

    private getDrawerContent(): React.ReactNode {
        const menuData: Array<IMenuEntry> = this.parseMenu(this.props.menuItems);

        return (
            <div>
                <NavigationImage href="/"/>
                <Divider />
                <NavigationWrapper>
                    <Location>
                        {locationContext => (
                            menuData.map((entry: IMenuEntry, index: number) => {
                                return (
                                    <div key={index}>
                                        {this.getNavigationEntryNode(
                                            entry,
                                            locationContext,
                                            index + 1,
                                            '',
                                        )}
                                    </div>
                                );
                            })
                        )}
                    </Location>
                </NavigationWrapper>
            </div>
        );
    }

    private getNavigationEntryNode(
        entry: IMenuEntry,
        locationContext: LocationContext,
        index: number,
        parentNumeration: string,
    ): React.ReactNode {
        const numeration: string = `${parentNumeration}${index}.`;

        return (
            <div>
                <NavigationEntry
                    key={entry.id}
                    href={entry.path}
                    // @ts-ignore
                    active={isMenuEntryActive(entry, locationContext)}
                    hasParent={entry.parentId !== null}
                    levelPadding={`${15 + ((entry.level - 1) * 5)}px`}
                    isChildActive={isChildMenuEntryActive(entry, locationContext)}
                >
                    {numeration} {entry.label}
                </NavigationEntry>
                {(entry.childItems && (isMenuEntryActive(entry, locationContext) || isChildMenuEntryActive(entry, locationContext))) &&
                entry.childItems.map((childItem: IMenuEntry, childIndex: number) => {
                    return (
                        <div key={childIndex}>{this.getNavigationEntryNode(
                            childItem,
                            locationContext,
                            childIndex + 1,
                            numeration,
                        )}</div>
                    );
                })
                }
            </div>
        );
    }

    private parseMenu(data: Array<IGraphQLMenuEntry>): Array<IMenuEntry> {
        return data
            .filter((entry: IGraphQLMenuEntry) => {
                return entry.parentId == null;
            })
            .map((entry: IGraphQLMenuEntry) => {
                return this.parseMenuEntry(entry, data, 1);
            });
    }

    private parseMenuEntry(entryData: IGraphQLMenuEntry, data: Array<IGraphQLMenuEntry>, level: number): IMenuEntry {
        const menuEntry: IMenuEntry = {
            id: entryData.id,
            label: entryData.label,
            path: entryData.path,
            parentId: entryData.parentId,
            level,
        };

        const childEntries: Array<IMenuEntry> = data
            .filter((entry: IGraphQLMenuEntry) => {
                return entry.parentId === entryData.id;
            })
            .map((entry: IGraphQLMenuEntry) => {
                return this.parseMenuEntry(entry, data, level + 1);
            });

        if (childEntries.length) {
            menuEntry.childItems = childEntries;
        }

        return menuEntry;
    }

    private handleDrawerToggle(): void {
        this.setState({isMenuOpened: !this.state.isMenuOpened});
    }

    private handleMobileDrawerToggle(): void {
        this.setState({isMobileMenuOpened: !this.state.isMobileMenuOpened});
    }

    private logout(): void {
        AuthToken.remove();
        location.reload();
    }

    private openGlossary(): void {
        location.href = `${location.pathname.startsWith('/en') ? '/en/glossary' : '/de/glossary'}`;
    }
}
function isLoginPage(): boolean {
    try {
        return location.pathname.includes('/login');
    } catch (error) {
        return true;
    }
}

function isMenuEntryActive(entry: IMenuEntry, locationContext: LocationContext) {
    const locationPath: string = locationContext.location.pathname.startsWith('/de') || locationContext.location.pathname.startsWith('/en')
        ? locationContext.location.pathname.replace('/de', '').replace('/en', '')
        : locationContext.location.pathname;

    return entry.path === locationContext.location.pathname
        || stripTrailingSlash(entry.path) === locationPath;
}

function isChildMenuEntryActive(entry: IMenuEntry, locationContext: LocationContext): boolean {
    if (!entry.childItems) {
        return false;
    }

    return entry.childItems.some((childEntry: IMenuEntry) => {
        return isMenuEntryActive(childEntry, locationContext) || isChildMenuEntryActive(childEntry, locationContext);
    });
}

function stripTrailingSlash(url: string): string {
    return url.endsWith('/') && url !== '/' ? url.slice(0, -1) : url;
}

interface ICustomAppBarProps extends AppBarProps {
    open?: boolean;
}
const GlobalAppBar = muiStyled(AppBar, {
    shouldForwardProp: (prop: any): boolean => prop !== 'open',
})<ICustomAppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
    marginLeft: 0,
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            marginLeft: 0,
        },
    }),
    backgroundColor: '#fff',
    color: 'rgba(0,0,0,0.87)',
}));
const ContentWrapper = muiStyled(Box, {
    shouldForwardProp: (prop: any): boolean => prop !== 'open',
})<ICustomAppBarProps>(({theme, open}) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
    marginLeft: 0,
    ...(open && {
        padding: '24px',
        width: `calc(100% - ${drawerWidth}px - 48px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            marginLeft: 0,
        },
    }),
    marginTop: 64 + 48,
    padding: '16px',
}));
const NavigationDrawer = muiStyled(Drawer)(() => ({
    '& .MuiPaper-root': {
        backgroundColor: '#F5F5F5',
        width: '240px',
        borderRight: 'none',
        boxShadow: '0 2px 4px -1px #0003,0 4px 5px #00000024,0 1px 10px #0000001f',
    },
}));

const NavigationImage = styled.a`
    background: url('/images/logo-black.svg') center center no-repeat;
    height: 64px;
    width: 75%;
    padding: 0;
    margin: 0 auto;
    display: block;
`;

const NavigationWrapper = styled.div`
    padding-top: 0 !important;
`;

const NavigationEntry = styled.a`
    color: #000000 !important;
    display: block;
    font-size: 14px;
    padding: 10px 10px 10px 15px;
    line-height: 20px;
    text-decoration: none;
    position: relative;

    :hover {
        background-color: rgba(158,158,158,0.2);
    }

    ${(props: any) => props.isChildActive && `
        background-color: #EAEAEA;
        font-weight: bold !important;
        border-top: none;

        :after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 7px;
            background-color: #39917b;
        }
    `}

    ${(props: any) => props.hasParent && `
        font-size: 12px;
        padding-left: ${props.levelPadding};
        font-weight: normal !important;
    `}

    ${(props: any) => props.active && `
        border-top: none;
        color: #fff !important;
        background-color: #39917b !important;
    `}
`;

const PageContent = styled(Card)(() => ({
    maxWidth: '1200px',
    margin: '0 auto 0 auto',
    overflow: 'visible !important',
}));

interface IMenuEntry {
    id: string;
    label: string;
    path: string;
    level: number;
    parentId: string | null;
    childItems?: Array<IMenuEntry>;
}

const connectedLayout = connect(mapStateToProps, {updateMenuItems})(Layout);

export {connectedLayout as Layout};
export type {IMenuEntry};
